<template>
  <div class="case">
    <div class="caseTop" id="top">
      <img v-lazy="topBg" alt="" srcset="">
    </div>
    <!-- 会员营销系统 -->
    <div class="caseMember" id="member">
      <div class="caseMember-top">
        <p>会员营销系统</p>
        <p>适用于中小心互联网企业及线下实体店</p>
      </div>
      <div class="caseMember-bottom">
        <div class="caseMember-bottom-left">
          <img v-lazy="memberImg" alt="" srcset="">
        </div>
        <div class="caseMember-bottom-bottom ">
          <div class="caseMember-bottom-bottom-list">
            <div class="caseMember-bottom-bottom-list-item" v-for="(item,index) in memberList.slice(0,2)" :key="index">
              <div class="caseMember-bottom-bottom-list-item-top">
                <p>{{ item.title }}</p><img :src="item.img" alt="" srcset="">
              </div>
              <div class="caseMember-bottom-bottom-list-item-bottom">
                <p v-if="item.title!='会员体系'&&item.title!='营销工具'">{{ item.content }}</p>
                <p v-if="item.title=='会员体系'||item.title=='营销工具'">{{ item.content.slice(0,17) }}</p>
                <p v-if="item.title=='会员体系'||item.title=='营销工具'">{{ item.content.slice(17) }}</p>
              </div>
            </div>
          </div>
          <div class="caseMember-bottom-bottom-list">
            <div class="caseMember-bottom-bottom-list-item" v-for="(item,index) in memberList.slice(2)" :key="index">
              <div class="caseMember-bottom-bottom-list-item-top">
                <p>{{ item.title }}</p><img :src="item.img" alt="" srcset="">
              </div>
              <div class="caseMember-bottom-bottom-list-item-bottom">
                <p v-if="item.title!='会员体系'&&item.title!='营销工具'">{{ item.content }}</p>
                <p v-if="item.title=='会员体系'||item.title=='营销工具'">{{ item.content.slice(0,16) }}</p>
                <p v-if="item.title=='会员体系'||item.title=='营销工具'">{{ item.content.slice(16) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 数字化保修系统 -->
    <div class="caseDigitizationr" id="digitization">
      <p>数字化报修系统</p>
      <p>适合酒店、物业、工厂等行业无纸化</p>
      <p>报修系统,节约资源，降本增效配合后</p>
      <p>台系统便捷管理报修员、维修员、保</p>
      <p>修单等数据</p>
      <img v-lazy="digitizationImg" alt="" srcset="">
    </div>
    <!-- 商城小程序 -->
    <div class="caseShopping" id="shopping">
      <div class="caseShopping-top">
        <p>商城小程序</p>
        <p>适用于大多数线上商城下单场景</p>
      </div>
      <div class="caseShopping-bottom">
        <img v-lazy="item.img" alt="" srcset="" v-for="(item,index) in shoppingList" :key="index">

      </div>

    </div>
    <!-- 家政上门服务小程序 -->
    <div class="caseDigitizationr" id="housekeeping">
        <p>家政上门服务小程序</p>
        <div class="caseDigitizationr-line"></div>
      <p>适用于上门服务型场景</p>
      <p>集发布任务需求与接单一体化系统</p>
      <p>多角色任意切换便捷操作</p>
      <img v-lazy="housekeepingImg" alt="" srcset="" class="img">
    </div>
    <!-- 无人值守预约小程序 -->
    <div class="caseUnmanned" id="unmanned">
      <div class="caseUnmanned-top">
        <p>无人值守预约小程序</p>
        <p>适用于各种无人场景，全场景解决方案</p>
      </div>
      <div class="caseUnmanned-bottom">
        <div>
          <p>一键在线预约</p>
          <p>无人值守门店，时间自主可控</p>
          <img v-lazy="unmannedImg1" alt="" srcset="">
        </div>
        <div>
          <p>自动结账</p>
          <p>全程智能化解放人力，自主选择卡券结账</p>
          <img v-lazy="unmannedImg2" alt="" srcset="">
        </div>
      </div>


      <!-- 蛋糕预约 -->
      <div class="caseCakeSubscribe" id="cakeSubscribe">
          <p>蛋糕预约小程序</p>
          <div class="caseCakeSubscribe-line"></div>
        <p>适用于个体门店预约下单场</p>
        <p>景 快速打造门店私域流量形</p>
        <p>成线上 线下引流，二次转</p>
        <p>化，持续增收</p>
        <img v-lazy="cakeSubscribeImg" alt="" srcset="" class="img">
      </div>


      <!-- 线上云酒馆 -->
      <div class="caseCloudTavern" id="cloudTavern">
        <div class="caseCloudTavern-top">
          <p>线上云酒馆</p>
          <p>适用于同城多站点门店配送场景</p>
        </div>
        <div class="caseCloudTavern-bottom">
          <div class="caseCloudTavern-bottom-left">
            <img v-lazy="cloudTavernImg" alt="" srcset="">
          </div>
          <div class="caseCloudTavern-bottom-bottom">
            <div class="caseCloudTavern-bottom-content1">
              <div class="caseCloudTavern-bottom-content-img">
                <img src="../../assets/Mi/case/cloudTavern/tag1.png" alt="">
              </div>
              <div class="caseCloudTavern-bottom-content-content">
                <p>合伙人功能，发展各大高校，
                露营地，轰趴馆，酒店代理，
                帮助拓客</p>
              </div>
            </div>
            <div class="caseCloudTavern-bottom-content2">
              <div class="caseCloudTavern-bottom-content-img">
                <img src="../../assets/Mi/case/cloudTavern/tag2.png" alt="">
              </div>
              <div class="caseCloudTavern-bottom-content-content">
              <p>对接打印机、支持会员、优惠
              券、招商加盟、合伙人分销、
              分类管理等功能</p>
              </div>
            </div>
          </div>
          <div class="caseCloudTavern-bottom-bottom1">
            <div class="caseCloudTavern-bottom-content3">
              <div class="caseCloudTavern-bottom-content-img">
                <img src="../../assets/Mi/case/cloudTavern/tag3.png" alt=""> 
              </div>
              <div class="caseCloudTavern-bottom-content-content">
                <p>涵盖站点门店下单配送费、站点管理等功能，帮助各站点更便捷的运营</p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- CRM系统 -->
      <div class="caseCrm" id="crm">
        <p>CRM系统</p>
        <div class="caseCrm-line"></div>

        <div class="caseCloudTavern-bottom-bottom">
          <div class="caseCloudTavern-bottom-content1">
            <div class="caseCloudTavern-bottom-content-symbol"></div>
            <div class="caseCloudTavern-bottom-content-content">
              <p>适用于各企业对客户数据 的统一管理</p>
            </div>
          </div>
          <div class="caseCloudTavern-bottom-content2">
            <div class="caseCloudTavern-bottom-content-symbol"></div>
            <div class="caseCloudTavern-bottom-content-content">
            <p>全流程销售数据分析，轻 松掌控企业业绩</p>
            </div>
          </div>
        </div>
        <div class="caseCloudTavern-bottom-bottom1">
          <div class="caseCloudTavern-bottom-content3">
            <div class="caseCloudTavern-bottom-content-symbol"></div>
            <div class="caseCloudTavern-bottom-content-content">
              <p>多维度管理：系统管理、客户管理、审批管理、业绩管理、 产品管理</p>
            </div>
          </div>
        </div>

        <img v-lazy="crmImg" alt="" srcset="" class="img">
      </div>

    </div>
  </div>
</template>
    
    <script>
// @ is an alias to /src
export default {
  name: 'Home',
  components: {
  },
  props: ['casePopName'],
  data() {
    return {
      topBg: require('../../assets/Mi/case/topBg.png'),
      memberImg: require('../../assets/Mi/case/member/img.png'),
      digitizationImg: require('../../assets/Mi/case/digitization/img.png'),
      housekeepingImg: require('../../assets/Mi/case/housekeeping/img.png'),
      unmannedImg1: require('../../assets/Mi/case/unmanned/1.png'),
      unmannedImg2: require('../../assets/Mi/case/unmanned/2.png'),
      cakeSubscribeImg: require('../../assets/Mi/case/cakeSubscribe/example.png'),
      cloudTavernImg: require('../../assets/Mi/case/cloudTavern/example.png'),
      crmImg: require('../../assets/Mi/case/crm/example.png'),

      one: true,//以此来控制每次轮播图切换的张数
      memberList: [
        {
          title: '多种卡券',
          img: require('../../assets/Web/case/member/1.png'),
          content: '优惠券、集次卡、预存卡等，提升用户粘性',
        },
        {
          title: '会员体系',
          img: require('../../assets/Web/case/member/3.png'),
          content: '包括会员管理、会员配置、会员等级管理、会员开卡赠礼等功能',
        },
        {
          title: '积分兑换',
          img: require('../../assets/Web/case/member/2.png'),
          content: '积分可用于兑换卡券或抵充现金使用',
        },

        {
          title: '营销工具',
          img: require('../../assets/Web/case/member/4.png'),
          content: '支持卡券营销、积分兑换、会员等级权益、开卡赠礼等丰富的营销工具',
        },


      ],
      shoppingList: [
        {
          img: require('../../assets/Mi/case/shopping/img.png'),
        },
        {
          img: require('../../assets/Mi/case/shopping/img2.png'),
        },
      ]
    }
  },
  watch: {
    casePopName: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.$nextTick(() => {
          document.querySelector('#' + newVal).scrollIntoView({
            block: 'center'
          });
        })
      },
    }
  },
  methods: {
    goWheel(event) {
      if (event.deltaY > 0 && this.one == true) {
        this.$refs.swiper.next();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
      }

      if (event.deltaY < 0 && this.one == true) {
        this.$refs.swiper.prev();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
      }
    },
  }
}
    </script>
    <style lang="scss" scoped>
.case {
  .caseTop {
    width: 100%;
    height: 377px;
    img {
      width: 100%;
      height: 377px;
    }
  }
  .caseMember {
    margin-top: 25px;
    margin-bottom: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .caseMember-top {
      text-align: center;
      margin-bottom: 11px;
      p:first-child {
        color: #000000;
        font-size: 23px;
        font-weight: bold;
      }
      p:last-child {
        margin-top: 10px;
        color: #999999;
        font-size: 13px;
      }
    }
    .caseMember-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      .caseMember-bottom-left {
        img {
          width: 350px;
          height: 267px;
        }
      }
      .caseMember-bottom-bottom {
        display: flex;
        align-items: center;
        // width: 100%;
        // justify-content: space-between;
        .caseMember-bottom-bottom-list:first-child {
          margin-right: 21px;
        }
        .caseMember-bottom-bottom-list {
          display: flex;
          flex-direction: column;
          .caseMember-bottom-bottom-list-item {
            display: flex;
            flex-direction: column;
            .caseMember-bottom-bottom-list-item-top {
              display: flex;
              align-items: center;
              margin-bottom: 2px;
              p {
                color: #333333;
                font-size: 13px;
                // font-weight: bold;
                font-family: SourceHanSansCN-Medium;
              }
              img {
                width: 14px;
                height: 14px;
                margin-left: 3px;
              }
            }
            .caseMember-bottom-bottom-list-item-bottom {
              margin-bottom: 14px;
              p {
                color: #c6c6c6;
                font-size: 12px;
                zoom: 0.75;
              }
            }
          }
        }
      }
    }
  }
  .caseDigitizationr {
    padding-top: 27px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-image: url("../../assets/Mi/case/bg.png");
    background-color: #F5F9FD;
    p:first-child {
      color: #000000;
      font-weight: bold;
      font-size: 23px;
      margin-bottom: 10px;
    }
    p {
      color: #999999;
      font-size: 13px;
      letter-spacing: 0.5px;
      align-self: flex-start;
    }
    .caseDigitizationr-line{
        align-self: flex-start;
        width: 100px;
        height: 2px;
        background-color: rgba($color: #005ED8, $alpha: 0.3);
        border-radius: 1px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    img {
      margin-top: 26px;
      width: 272px;
      height: 298px;
    }
    .img{
        width: 268px;
      height: 296px;
    }
  }
  .caseShopping {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .caseShopping-top {
      text-align: center;
      p:first-child {
        color: #000000;
        font-size: 26px;
        font-weight: bold;
      }
      p:last-child {
        margin-top: 11px;
        color: #999999;
        font-size: 13px;
      }
    }
    .caseShopping-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      // padding-left: 339px;
      // padding-right: 339px;
        img {
          width: 344px;
          height: 165px;
        }
    }
  }
  .caseUnmanned {
    margin-top: 25px;
    margin-bottom: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .caseUnmanned-top {
      text-align: center;
      margin-bottom: 20px;
      p:first-child {
        color: #000000;
        font-size: 23px;
        font-weight: bold;
      }
      p:last-child {
        margin-top: 10px;
        color: #999999;
        font-size: 13px;
      }
    }
    .caseUnmanned-bottom {
      display: flex;
      flex-direction: column;
      img {
        z-index: -1;
        top: 0;
        bottom: 0;
        position: absolute;
        width: 344px;
        height: 331px;
      }
     div:first-child{
        margin-bottom: 27px;
        p:first-child{
        color: #CE9EFF;
      }
      p:nth-child(2){
        color: #CE9EFF;
      }
     }
     div{
      position: relative;
      width: 344px;
        height: 331px;
      p:first-child{
        color: #52A0EE;
        font-family:SourceHanSansCN-Medium ;
        font-size: 15px;
        margin-top: 15px;
        margin-left: 26px;
      }
      p:nth-child(2){
        color: #8AC2FA;
        font-size: 12px;
        zoom: 0.75;
        margin-top: 4px;
        margin-left: 35px;
      }
     }
    }


    
    .caseCakeSubscribe {
      margin-top: 25px;
      padding-top: 27px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 29px;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      background-color: #F5F9FD;
      p:first-child {
        color: #000000;
        font-weight: bold;
        font-size: 23px;
        margin-bottom: 10px;
      }
      p {
        color: #999999;
        font-size: 13px;
        letter-spacing: 0.5px;
        align-self: flex-start;
      }
      .caseCakeSubscribe-line{
          align-self: flex-start;
          width: 100px;
          height: 2px;
          background-color: rgba($color: #005ED8, $alpha: 0.3);
          border-radius: 1px;
          margin-top: 10px;
          margin-bottom: 20px;
      }
      img {
        margin-top: 26px;
        width: 272px;
        height: 298px;
      }
      .img{
          width: 345px;
        height: 266px;
      }
    }

    
    
    .caseCloudTavern {
      margin-top: 25px;
      margin-bottom: 11px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .caseCloudTavern-top {
        text-align: center;
        margin-bottom: 11px;
        p:first-child {
          color: #000000;
          font-size: 23px;
          font-weight: bold;
        }
        p:last-child {
          margin-top: 10px;
          color: #999999;
          font-size: 13px;
        }
      }
      .caseCloudTavern-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        .caseCloudTavern-bottom-left {
          img {
            width: 370px;
            height: 239px;
          }
        }
        .caseCloudTavern-bottom-bottom {
          display: flex;
          margin-left: 15px;

          .caseCloudTavern-bottom-content1 {
            display: flex;
          }

          .caseCloudTavern-bottom-content2 {
            display: flex;
            margin-left: 20px;
            margin-right: 15px;
          }
        }
        .caseCloudTavern-bottom-bottom1 {
          margin-top: 22px;
          margin-left: 15px;
          margin-right: 15px;

          .caseCloudTavern-bottom-content3 {
            display: flex;
          }
        }
        
        .caseCloudTavern-bottom-content-img {
          width: 13px;
          img {
            width: 13px;
            height: 13px;
            display: inline;
            vertical-align:middle;
          }
        }
        .caseCloudTavern-bottom-content-content {
          margin-left: 3.5px;
          p {
            font-size: 11px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            display: inline;
            vertical-align:middle;
          }
        }

      }
    }

    
    .caseCrm {
      margin-top: 25px;
      padding-top: 27px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 29px;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      background-color: #F5F9FD;
      p:first-child {
        color: #000000;
        font-weight: bold;
        font-size: 23px;
        margin-bottom: 10px;
      }
      p {
        color: #999999;
        font-size: 13px;
        letter-spacing: 0.5px;
        align-self: flex-start;
      }
      .caseCrm-line{
          align-self: flex-start;
          width: 100px;
          height: 2px;
          background-color: rgba($color: #005ED8, $alpha: 0.3);
          border-radius: 1px;
          margin-top: 10px;
          margin-bottom: 20px;
      }
      img {
        margin-top: 24px;
        width: 272px;
        height: 298px;
      }
      .img{
          width: 345px;
        height: 266px;
      }

      .caseCloudTavern-bottom-bottom {
        display: flex;
        margin-left: 15px;

        .caseCloudTavern-bottom-content1 {
          display: flex;
        }

        .caseCloudTavern-bottom-content2 {
          display: flex;
          margin-left: 20px;
          margin-right: 15px;
        }
      }
      .caseCloudTavern-bottom-bottom1 {
        margin-top: 22px;
        margin-left: 15px;
        margin-right: 15px;

        .caseCloudTavern-bottom-content3 {
          display: flex;
        }
      }
      
      .caseCloudTavern-bottom-content-symbol {
          width: 5px;
          height: 5px;
          background-color: #85C2FF;
          margin-right: 3px;
          border-radius: 50%;
          display: inline;
          vertical-align:middle;
        }

        .caseCloudTavern-bottom-content-content {
          margin-left: 3.5px;
          margin-top: -5px;
          p {
            font-size: 11px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
          display: inline;
          vertical-align:middle;
        }

        
    }


  }
}
</style>
    